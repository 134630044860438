@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  display: flex;
  width: calc(100% * 2); /* Adjust the width to fit content twice for seamless scrolling */
  animation: scroll 20s linear infinite; /* Adjust duration for scrolling speed */
}

/* From Uiverse.io by Smit-Prajapati */ 
.card {
  position: relative;
  width: 200px;
  height: 200px;
  background: lightgrey;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 1s ease-in-out;
}

.background {
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at 100% 107%, #ff89cc 0%, #9cb8ec 30%, #00ffee 60%, #62c2fe 100%);
}

.logo {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  transition: all 0.6s ease-in-out;
}

.logo .logo-svg {
  fill: white;
  width: 30px;
  height: 30px;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon .svg {
  fill: rgba(255, 255, 255, 0.797);
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.box {
  position: absolute;
  padding: 10px;
  text-align: right;
  background: rgba(255, 255, 255, 0.389);
  border-top: 2px solid rgb(255, 255, 255);
  border-right: 1px solid white;
  border-radius: 10% 13% 42% 0%/10% 12% 75% 0%;
  box-shadow: rgba(100, 100, 111, 0.364) -7px 7px 29px 0px;
  transform-origin: bottom left;
  transition: all 1s ease-in-out;
}

.box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.box:hover .svg {
  fill: white;
}

.box1 {
  width: 70%;
  height: 70%;
  bottom: -70%;
  left: -70%;
}

.box1::before {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #ff53d4 60%, #62c2fe 90%);
}

.box1:hover::before {
  opacity: 1;
}

.box1:hover .icon .svg {
  filter: drop-shadow(0 0 5px white);
}

.box2 {
  width: 50%;
  height: 50%;
  bottom: -50%;
  left: -50%;
  transition-delay: 0.2s;
}

.box2::before {
  background: radial-gradient(circle at 30% 107%, #91e9ff 0%, #00ACEE 90%);
}

.box2:hover::before {
  opacity: 1;
}

.box2:hover .icon .svg {
  filter: drop-shadow(0 0 5px white);
}

.box3 {
  width: 30%;
  height: 30%;
  bottom: -30%;
  left: -30%;
  transition-delay: 0.4s;
}

.box3::before {
  background: radial-gradient(circle at 30% 107%, #969fff 0%, #b349ff 90%);
}

.box3:hover::before {
  opacity: 1;
}

.box3:hover .icon .svg {
  filter: drop-shadow(0 0 5px white);
}

.box4 {
  width: 10%;
  height: 10%;
  bottom: -10%;
  left: -10%;
  transition-delay: 0.6s;
}

.card:hover {
  transform: scale(1.1);
}

.card:hover .box {
  bottom: -1px;
  left: -1px;
}

.card:hover .logo {
  transform: translate(0, 0);
  bottom: 20px;
  right: 20px;
}
/* From Uiverse.io by alexruix */ 
.card {
  width: 190px;
  height: 254px;
  border-radius: 20px;
  background: #f5f5f5;
  position: relative;
  padding: 1.8rem;
  border: 2px solid #c3c6ce;
  transition: 0.5s ease-out;
  overflow: visible;
 }
 
 .card-details {
  color: black;
  height: 100%;
  gap: .5em;
  display: grid;
  place-content: center;
 }
 
 .card-button {
  transform: translate(-50%, 125%);
  width: 60%;
  border-radius: 1rem;
  border: none;
  background-color: #008bf8;
  color: #fff;
  font-size: 1rem;
  padding: .5rem 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: 0.3s ease-out;
 }
 
 .text-body {
  color: rgb(134, 134, 134);
 }
 
 /*Text*/
 .text-title {
  font-size: 1.5em;
  font-weight: bold;
 }
 
 /*Hover*/
 .card:hover {
  border-color: #008bf8;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
 }
 
 .card:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
 }
 @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 2s ease-in-out;
}
/* From Uiverse.io by kamehame-ha */ 
.cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cards .red {
  background-color: #f43f5e;
}

.cards .blue {
  background-color: #3b82f6;
}

.cards .green {
  background-color: #22c55e;
}

.cards .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100px;
  width: 250px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 400ms;
}

.cards .card p.tip {
  font-size: 1em;
  font-weight: 700;
}

.cards .card p.second-text {
  font-size: .7em;
}

.cards .card:hover {
  transform: scale(1.1, 1.1);
}

.cards:hover > .card:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}